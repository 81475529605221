<template>
   <div class="col-12 px-0 mb-1" ref="usuario">
      <div class="card mb-0" :class="[index % 2 == 1 ? 'invert' : '', usuario.indicadores.Financeiro < 3 && usuario.indicadores.Financeiro > 0 ? 'border-danger' : '']">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-lg-block">
                  <img :src="usuario.avatarUsuario == null ? '' : usuario.avatarUsuario" class="rounded" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-md text-center text-md-start cursor-pointer" @click="openInfo">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ usuario.nomeUsuario }}</h1>
                        <p class="font-10 mb-0 mb-lg-2 limitador-1 text-secondary">
                           <span><i class="far fa-user font-9 me-1"></i>{{ usuario.idUsuario }}</span>
                           <span class="ms-3">
                              <i class="far fa-envelope font-9 me-1"></i>
                              <span>{{ usuario.emailUsuario == null || String(usuario.emailUsuario).trim().length == 0 ? 'Sem email' : usuario.emailUsuario }}</span>
                           </span>
                        </p>
                     </div>
                     <div class="col d-none d-lg-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-phone-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Telefone:</strong>
                           <span> {{ usuario.telefoneUsuario.telefone == null || usuario.telefoneUsuario.telefone == null || String(usuario.telefoneUsuario.telefone).trim().length == 0 ? 'Sem telefone' : (usuario.telefoneUsuario.ddd +' '+ usuario.telefoneUsuario.telefone) }}</span>
                        </p>
                     </div>
                     <div class="col-9 d-none d-lg-block">
                        <p class="font-13 mb-0 limitador-1">
                           <span class="badge me-2" :class="usuario.indicadores.Financeiro == 0 ? 'text-bg-secondary' : usuario.indicadores.Financeiro < 3 ? 'text-bg-danger' : 'text-bg-success'">
                              <i class="far fa-dollar-sign font-10 me-1"></i><span class="d-none d-lg-inline">F</span>
                              <span>{{usuario.indicadores.Financeiro}}</span>
                           </span>
                           <span class="badge me-2" :class="usuario.indicadores.Tecnico == 0 ? 'text-bg-secondary' : usuario.indicadores.Tecnico == 1 ? 'text-bg-danger' : 'text-bg-success'">
                              <i class="far fa-tools font-10 me-1"></i><span class="d-none d-lg-inline">T</span>
                              <span>{{usuario.indicadores.Tecnico}}</span>
                           </span>
                           <span class="badge me-2" :class="usuario.indicadores.Operacional == 0 ? 'text-bg-secondary' : usuario.indicadores.Operacional == 1 ? 'text-bg-danger' : 'text-bg-success'">
                              <i class="far fa-tasks font-10 me-1"></i><span class="d-none d-lg-inline">O</span>
                              <span>{{usuario.indicadores.Operacional}}</span>
                           </span>
                           <span class="badge me-2" :class="usuario.indicadores.Calendario == 0 ? 'text-bg-secondary' : usuario.indicadores.Calendario <= 3 ? 'text-bg-danger' : 'text-bg-success'">
                              <i class="far fa-calendar font-10 me-1"></i><span class="d-none d-lg-inline">C</span>
                              <span>{{usuario.indicadores.Calendario}}</span>
                           </span>
                           <span class="badge me-2" :class="usuario.indicadores.Tecnologia == 0 ? 'text-bg-secondary' : usuario.indicadores.Tecnologia < 3 ? 'text-bg-danger' : 'text-bg-success'">
                              <i class="far fa-microchip font-10 me-1"></i><span class="d-none d-lg-inline">Tec</span>
                              <span>{{usuario.indicadores.Tecnologia}}</span>
                           </span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <div class="btn-icone color-theme">
                     <span @click="openInfo"><i class="far fa-user"></i><small>Visualizar</small></span>
                  </div>
                  <div class="btn-icone text-red" v-if="dadosUsuario.usuarioPermissoes.includes('Editar contas')">
                     <span @click="remover" v-if="dadosUsuario.usuarioId != usuario.id"><i class="far fa-trash"></i><small>Excluir</small></span>
                     <span class="text-secondary" v-else><i class="far fa-lock"></i><small>Bloqueado</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import router from '@/router'

export default {
   name: 'Conta',
   props: ['usuario', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      openInfo : function () {
         let tabInfo = this.tabs.find(tab => tab.nome == 'Conta' && tab.query.idConta == this.usuario.idConta)
			router.push('/info/'+ (tabInfo ? tabInfo.id : this.tabs.length) +'?idConta='+ this.usuario.idConta)
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir conta?',
            text: 'A conta perderá acesso ao sistema.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'configuracoes/deleteContaUsuario',
                  params: {
                     id: this.usuario.idConta
                  }
               }).then(() => {
                  this.$refs.usuario.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Conta excluída!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>